<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div :id="anchorName">
    <h3 v-if="name" class="dynamic-main-title tmw-40-32-15 mb-5">{{ name }}</h3>
    <v-container class="inner-container">
      <v-row class="mx-lg-10 justify-center">
        <v-col v-for="item in items" :key="item.id" cols="12" xl="4" lg="4">
          <CountItem :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
export default {
  mixins: [dynamicBlock],
  components: {
    CountItem: () => import("./countItem.vue"),
  },
};
</script>